var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "archive pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-toolbar",
                { staticStyle: { cursor: "default" }, attrs: { flat: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "ml-2 title" },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-file-upload-outline"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$route.name) + " Files "),
                    ],
                    1
                  ),
                  _c("v-divider", {
                    staticClass: "mx-4",
                    attrs: { inset: "", vertical: "" },
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: {
                            id: "uploadBtn",
                            color: "success",
                            loading: _vm.loading,
                            disabled: _vm.loading,
                          },
                          on: { click: _vm.saveDocument },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-file-upload-outline")]),
                          _vm._v(" Save "),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "warning" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v(" Reset ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Name",
                                  dense: "",
                                  required: "",
                                  rules: [
                                    function (v) {
                                      return !!v || "Name is required"
                                    },
                                  ],
                                  "validate-on-blur": "",
                                  outlined: "",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.doc.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.doc, "name", $$v)
                                  },
                                  expression: "doc.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Description",
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.doc.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.doc, "description", $$v)
                                  },
                                  expression: "doc.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("DocumentTags", {
                        model: {
                          value: _vm.doc.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.doc, "tags", $$v)
                          },
                          expression: "doc.tags",
                        },
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-file-input", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  multiple: "",
                                  "hide-details": "auto",
                                  "truncate-length": "100",
                                  label: "Files",
                                },
                                on: { change: _vm.handleFiles },
                                model: {
                                  value: _vm.files,
                                  callback: function ($$v) {
                                    _vm.files = $$v
                                  },
                                  expression: "files",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("DocPartyTable", {
                                attrs: { docId: _vm.doc.id || null },
                                model: {
                                  value: _vm.docPartyIds,
                                  callback: function ($$v) {
                                    _vm.docPartyIds = $$v
                                  },
                                  expression: "docPartyIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }