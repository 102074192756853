var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "", outlined: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fff" } },
        [
          _c("v-toolbar-title", { staticClass: "body" }, [
            _vm._v("Parties with Access"),
          ]),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { vertical: "", inset: "" },
          }),
          _c(
            "v-btn-toggle",
            {
              staticClass: "btnGroup",
              attrs: {
                dense: "",
                mandatory: "",
                "active-class": "activeToggle",
              },
              model: {
                value: _vm.addParties,
                callback: function ($$v) {
                  _vm.addParties = $$v
                },
                expression: "addParties",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "border-right": "1px solid #9e9e9e" },
                  attrs: { height: "38" },
                  on: {
                    click: function ($event) {
                      _vm.addParties = false
                      _vm.search = ""
                    },
                  },
                },
                [
                  _c("small", [_vm._v("All")]),
                  _c("v-icon", { staticClass: "ml-1", attrs: { small: "" } }, [
                    _vm._v(" mdi-format-list-bulleted "),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticStyle: {
                    "border-top-right-radius": "0px",
                    "border-bottom-right-radius": "0px",
                  },
                  attrs: { height: "38" },
                  on: {
                    click: function ($event) {
                      _vm.addParties = true
                    },
                  },
                },
                [
                  _c("small", [_vm._v("Add Parties")]),
                  _c("v-icon", { staticClass: "ml-1", attrs: { small: "" } }, [
                    _vm._v(" mdi-plus "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0",
              staticStyle: { height: "64px" },
              attrs: { cols: "3" },
            },
            [
              _c("v-text-field", {
                staticStyle: {
                  "border-top-left-radius": "0px",
                  "border-bottom-left-radius": "0px",
                },
                attrs: {
                  label: _vm.addParties ? "Search Parties" : "Filter Parties",
                  outlined: "",
                  dense: "",
                  "append-icon": "mdi-window-close",
                },
                on: {
                  "click:append": function ($event) {
                    _vm.search = ""
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend-inner",
                    fn: function () {
                      return [
                        _c("v-icon", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.addParties
                                  ? "mdi-magnify"
                                  : "mdi-filter-outline"
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        attrs: {
          height: "300px",
          loading: _vm.loading,
          headers: _vm.headers,
          search: _vm.search,
          items: _vm.tableItems,
          "items-per-page": 100,
          "footer-props": { "items-per-page-options": [50, 100, 200, -1] },
          "item-key": "id",
          "fixed-header": "",
          "no-data-text": _vm.addParties
            ? "Please search for a party"
            : "No data to show",
          dense: "",
        },
        on: {
          "update:search": function ($event) {
            _vm.search = $event
          },
          "update:items": function ($event) {
            _vm.tableItems = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.actions",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm.addParties
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "open-delay": "500" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            icon: "",
                                            small: "",
                                            color: "green",
                                            disabled: _vm.docPartyIds.includes(
                                              item.id
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.add(item)
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-plus-circle"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Add")])]
                      )
                    : _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "open-delay": "500" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(item)
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "red" } },
                                          [_vm._v("mdi-trash-can-outline")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Remove")])]
                      ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "v-dialog",
        {
          attrs: { justify: "center", persistent: "", width: "500" },
          model: {
            value: _vm.removeDialog,
            callback: function ($$v) {
              _vm.removeDialog = $$v
            },
            expression: "removeDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "ma-0 pa-6" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-icon", { attrs: { "x-large": "", color: "orange" } }, [
                    _vm._v("mdi-alert-circle-outline"),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "py-6", attrs: { justify: "center" } },
                [
                  _c("h3", [
                    _vm._v("Remove " + _vm._s(_vm.docParty.name) + "?"),
                  ]),
                ]
              ),
              _c(
                "v-row",
                { staticClass: "py-4", attrs: { justify: "center" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "elevation-3 white--text",
                                    attrs: { fab: "", small: "", color: "red" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        _vm.removeDialog = false
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Cancel")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "white--text elevation-3 ml-2",
                                    attrs: {
                                      fab: "",
                                      small: "",
                                      color: "green",
                                    },
                                    on: { click: _vm.remove },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-check")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Confirm")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }